/**
 * 全站http配置
 *
 * axios参数说明
 * isSerialize是否开启form表单提交
 * isToken是否需要token
 */
import axios from "axios";

axios.interceptors.request.use(function (config) {
  //比如是否需要设置 token
  config.headers.Authorization =
    "Basic aDU6MjQ3N2I4MGE3OTljYjVlMDJjNzlkYzU5NDMzNzc1NTM=";
  return config;
});

export default axios;
