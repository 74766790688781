<template>
  <div class="main">
    <div class="logo">
      <img src="@/static/img/icon_logo.png" class="logoImg" />
    </div>
    <div class="banner">
      <img src="@/static/img/bg_banner.png" class="bannerImg" />
    </div>
    <div class="wrap">
      <div class="sectionOne">
        <div class="soa">
          <div class="sob"></div>
          <div class="soc">训练账号激活</div>
        </div>

        <template v-if="isActive">
          <div class="iaa" style="margin-top: 30px">
            <div class="iab">训练账号</div>
            <div class="iac">{{ account }}</div>
          </div>
          <div class="iaa">
            <div class="iab">家长手机号</div>
            <div class="iac">{{ phone }}</div>
          </div>
          <div class="iaa">
            <div class="iab">患者名称</div>
            <div class="iac">{{ name }}</div>
          </div>
          <div class="iaBtn">账号已激活</div>
        </template>

        <template v-else>
          <div class="sod">请输入信息激活训练账号</div>
          <input
            class="soh"
            type="text"
            v-model="account"
            placeholder="请输入训练账号"
            maxlength="8"
          />
          <input
            class="soh"
            type="number"
            v-model="phone"
            placeholder="请输入家长手机号"
            maxlength="11"
          />
          <div class="soi">
            <input
              class="soj"
              type="number"
              v-model="smsCode"
              placeholder="请输入手机验证码"
              maxlength="6"
            />
            <div
              class="sok"
              @click="sendCode"
              :style="'background:' + sendColor"
            >
              {{ sendTime }}
            </div>
          </div>

          <!-- <div class="picker">当前选择：{{array[index]}}</div> -->
          <div class="pickerWrap" @click="showPickerFn">
            <div class="picker">
              {{ name ? name : "请选择患者姓名" }}
            </div>
            <img src="@/static/img/icon_jt.png" class="jtImg" />
          </div>
          <van-popup v-model="showPicker" position="bottom">
            <van-picker
              show-toolbar
              :columns="patientList"
              value-key="name"
              @confirm="onConfirm"
              @cancel="showPicker = false"
            />
          </van-popup>
          <input
            class="soh"
            type="password"
            v-model="password"
            placeholder="请输入登录密码"
          />

          <div class="soBtn" @click="confirmFn">确认激活</div>
        </template>
      </div>
      <div class="sectionTwo">
        <div class="sta">
          <div class="stb" style="margin-right: 10px">
            <div class="stc">
              <img src="@/static/img/icon01.png" class="stImg" />
            </div>
            <div class="std">专业</div>
            <div class="ste">资深眼科专家团队提供专业诊断和训练服务</div>
          </div>
          <div class="stb">
            <div class="stc">
              <img src="@/static/img/icon02.png" class="stImg" />
            </div>
            <div class="std">安全</div>
            <div class="ste">科学训练方案保障患儿眼睛健康</div>
          </div>
        </div>
        <div class="sta" style="margin-top: 10px">
          <div class="stb" style="margin-right: 10px">
            <div class="stc">
              <img src="@/static/img/icon03.png" class="stImg" />
            </div>
            <div class="std">趣味</div>
            <div class="ste">游戏化训练方式，提升训练效果</div>
          </div>
          <div class="stb">
            <div class="stc">
              <img src="@/static/img/icon04.png" class="stImg" />
            </div>
            <div class="std">有效</div>
            <div class="ste">临床结果验证训练课有效改善弱视病症并防止发生</div>
          </div>
        </div>
      </div>
      <!-- <div class="consult">
        <img src="@/static/img/button_01.png" class="consultImg" />
        <div class="consultTel">15011112222</div>
      </div>
      <div class="sectionOne">
        <div class="soa">
          <div class="sob"></div>
          <div class="soc">加盟机构</div>
        </div>
        <div class="mechanismWrap">
          <div class="mechanismList">
            <div class="mwa">
              <img src="@/static/img/icon02.png" class="mwb" />
            </div>
            <div class="mwc">
              <div class="mwd">深圳雅视力眼科诊所本部</div>
              <div class="mwe">地址：深圳市深南大道1号</div>
            </div>
          </div>
        </div>
      </div> -->
    </div>
  </div>
</template>

<script>
/* eslint-disable */
import CryptoJS from "crypto-js";
import { appid, secret } from "./util/signData";
import md5 from "js-md5";
let url = "https://h5.drxtek.com";
export default {
  name: "App",
  data() {
    return {
      showPicker: false,
      isActive: false,

      account: "", //账号
      phone: "", //手机号
      sendTime: "获取验证码",
      sendColor: "#0052d9",
      snsMsgWait: 60,

      smsCode: "", //短信验证码
      smsId: "", //短信id，发送短信验证码接口返回的id
      password: "",
      patientId: "",
      name: "",
      patientList: [
        {
          patientId: "1604108804110413826",
          name: "H5测试患者",
        },
      ],
    };
  },
  components: {},
  methods: {
    onConfirm(value) {
      console.log("value", value);
      this.name = value.name;
      this.patientId = value.patientId;
      this.showPicker = false;
    },
    showPickerFn() {
      this.showPicker = true;
    },
    sendCode() {
      if (this.smsFlag) return;
      if (this.phone == "") {
        this.$toast("请输入手机号");
        return;
      }
      let encryptText = "";
      let timeStamp = new Date().getTime();
      let params =
        "account=" +
        this.account +
        "&appId=" +
        appid +
        "&phone=" +
        this.phone +
        "&timeStamp=" +
        timeStamp;
      let signature = encodeURIComponent(
        CryptoJS.enc.Base64.stringify(CryptoJS.HmacSHA1(params, secret))
      );
      encryptText =
        "account=" +
        this.account +
        "&appId=" +
        appid +
        "&phone=" +
        this.phone +
        "&signature=" +
        signature +
        "&timeStamp=" +
        timeStamp;

      this.$http
        .post(
          url +
            "/doctor-x/experienceCard/active-account-send-validate?" +
            encryptText
        )
        .then((res) => {
          if (res.data.code == 200) {
            this.smsId = res.data.data.id;
            this.$toast("短信验证码发送成功");
            this.queryPatientFn();
            // 60秒后重新获取验证码
            var inter = setInterval(
              function () {
                this.smsFlag = true;
                this.sendColor = "#cccccc";
                this.sendTime = this.snsMsgWait + "s后重发";
                this.snsMsgWait = this.snsMsgWait - 1;
                if (this.snsMsgWait < 0) {
                  clearInterval(inter);
                  this.sendColor = "#0052d9";
                  this.sendTime = "发送验证码";
                  this.snsMsgWait = 60;
                  this.smsFlag = false;
                }
              }.bind(this),
              1000
            );
          } else {
            this.$toast(res.data.msg);
          }
        })
        .catch((err) => {
          console.log("err", err);
        });
    },
    queryPatientFn() {
      let encryptText = "";
      let timeStamp = new Date().getTime();
      let params =
        "appId=" + appid + "&phone=" + this.phone + "&timeStamp=" + timeStamp;
      let signature = encodeURIComponent(
        CryptoJS.enc.Base64.stringify(CryptoJS.HmacSHA1(params, secret))
      );
      encryptText =
        "appId=" +
        appid +
        "&phone=" +
        this.phone +
        "&signature=" +
        signature +
        "&timeStamp=" +
        timeStamp;

      this.$http
        .get(
          url +
            "/doctor-x/experienceCard/queryPatientWithOutAccountByPhone?" +
            encryptText
        )
        .then((res) => {
          if (res.data.code == 200) {
            this.patientList = res.data.data;
          } else {
            this.$toast(res.data.msg);
          }
        })
        .catch((err) => {
          console.log("err", err);
        });
    },
    confirmFn() {
      if (!this.account) {
        this.$toast("请输入训练账号");
        return;
      }
      if (!this.phone) {
        this.$toast("请输入家长手机号");
        return;
      }
      if (!this.smsCode) {
        this.$toast("请输入手机验证码");
        return;
      }
      if (!this.patientId) {
        this.$toast("请选择患者");
        return;
      }
      if (!this.password) {
        this.$toast("请输入登录密码");
        return;
      }
      let params = {};
      params.account = this.account;
      params.password = md5(this.password);
      params.patientId = this.patientId;
      params.phone = this.phone;
      params.smsCode = this.smsCode;
      params.smsId = this.smsId;
      this.$http
        .post(url + "/doctor-x/experienceCard/active", params)
        .then((res) => {
          if (res.data.code == 200) {
            this.$toast("激活成功");
            this.isActive = true;
          } else {
            this.$toast(res.data.msg);
          }
        })
        .catch((err) => {
          console.log("err", err);
        });
    },
  },
};
</script>

<style scoped lang="scss">
.main {
  background: #0646f0;
  min-height: 100vh;
}
.logo {
  position: absolute;
  top: 25px;
  left: 18px;
  width: 135px;
  z-index: 2;
}
.logoImg {
  width: 100%;
}
.banner {
  position: absolute;
  top: -55px;
  left: 0;
  width: 100%;
  z-index: 1;
}
.bannerImg {
  width: 100%;
}
.wrap {
  position: absolute;
  top: calc(100vw - 90px);
  left: 0;
  z-index: 3;
  width: 100%;
  background: #0646f0;
}
.sectionOne {
  background: #ffffff;
  box-shadow: 0px 2px 10px 0px rgba(0, 57, 209, 1);
  border-radius: 12px;
  margin: 0 20px;
  padding: 20px 15px;
}

.soa {
  display: flex;
  align-items: center;
}
.sob {
  width: 4px;
  height: 18px;
  background: #0052d9;
  border-radius: 2px;
}
.soc {
  font-size: 19px;
  color: #202020;
  font-weight: 500;
  margin-left: 7px;
}
.sod {
  font-size: 12px;
  color: #666666;
  margin: 8px 0 16px 0;
}
.soe {
  width: 100%;
  height: 45px;
  background: #f6f6f6;
  border-radius: 2px;
  display: flex;
  align-items: center;
  padding-left: 12px;
  box-sizing: border-box;
}
.sof {
  font-size: 14px;
  color: #999999;
}
.sog {
  font-size: 14px;
  color: #0052d9;
  font-weight: 500;
}
.soh {
  width: 100%;
  height: 45px;
  background: #ffffff;
  border: 1px solid rgba(196, 196, 196, 1);
  border-radius: 2px;
  margin-top: 10px;
  padding-left: 12px;
  box-sizing: border-box;
}
.soj {
  width: 100%;
  border: none;
}
.soi {
  width: 100%;
  height: 45px;
  background: #ffffff;
  border: 1px solid rgba(196, 196, 196, 1);
  border-radius: 2px;
  margin-top: 10px;
  padding: 0 12px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  box-sizing: border-box;
}
.sok {
  min-width: 85px;
  height: 25px;
  background: #0052d9;
  border-radius: 2px;
  color: #ffffff;
  font-size: 11px;
  display: flex;
  align-items: center;
  justify-content: center;
  white-space: nowrap;
  padding: 0 10px;
}
.pickerWrap {
  width: 100%;
  height: 45px;
  background: #ffffff;
  border: 1px solid rgba(196, 196, 196, 1);
  border-radius: 2px;
  margin-top: 10px;
  padding: 0 12px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  box-sizing: border-box;
}
.picker {
  font-size: 16px;
  color: #666666;
}
.jtImg {
  width: 12px;
  height: 12px;
}
.soBtn {
  width: 100%;
  height: 45px;
  background: #0052d9;
  border-radius: 2px;
  font-size: 15px;
  color: #ffffff;
  font-weight: 500;
  margin-top: 15px;
  display: flex;
  align-items: center;
  justify-content: center;
  box-sizing: border-box;
}
.sectionTwo {
  padding: 15px 20px;
}
.sta {
  display: flex;
}
.stb {
  width: 162px;
  height: 155px;
  background-image: linear-gradient(132deg, #ffffff 27%, #deebfd 100%);
  box-shadow: 0px 5px 10px 0px rgba(0, 57, 209, 1);
  border-radius: 8px;
  display: flex;
  align-items: center;
  flex-direction: column;
  padding: 12px 21px;
  justify-content: center;
}
.stc {
  width: 56px;
  height: 56px;
}
.stImg {
  width: 56px;
  height: 56px;
}
.std {
  font-size: 17px;
  color: #0052d9;
  font-weight: 500;
  margin: 8px 0;
}
.ste {
  font-family: PingFang-SC-Medium;
  font-size: 12px;
  color: #606060;
  text-align: center;
  font-weight: 500;
}
.consult {
  position: relative;
}
.consultTel {
  position: absolute;
  z-index: 1;
  bottom: 10vw;
  left: 32%;

  font-size: 14px;
  color: #ffffff;
}
.consultImg {
  width: 100%;
}
.mechanismWrap {
  margin-top: 33px;
}
.mechanismList {
  display: flex;
  align-items: center;
  padding: 10px;
  background: #e8eef7;
  border-radius: 4px;
}
.mwa {
  width: 55px;
  height: 55px;
}
.mwb {
  width: 100%;
}
.mwc {
  margin-left: 10px;
}
.mwd {
  font-size: 15px;
  color: #202020;
  font-weight: bold;
}
.mwe {
  font-size: 12px;
  color: #666666;
  margin-top: 10px;
}
.iaa {
  display: flex;
  align-items: center;
  margin: 0 0 20px 10px;
}
.iab {
  margin-right: 15px;
  font-weight: bold;
}
.iac {
  color: #333333;
}
.iaBtn {
  background: #3cb95a;
  color: #ffffff;
  width: 100%;
  margin-top: 25px;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 40px;
  border-radius: 4px;
}
.ieBtn {
  background: #cccccc;
  color: red;
  width: 100%;
  margin-top: 25px;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 40px;
  border-radius: 4px;
}
</style>
