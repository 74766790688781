import Vue from "vue";
import App from "./App.vue";
import Vant from "vant";
import "vant/lib/index.css";

import axios from "./util/axios";
import VueAxios from "vue-axios";

Vue.use(Vant);

Vue.use(VueAxios, axios);

Vue.config.productionTip = false;

new Vue({
  render: (h) => h(App),
}).$mount("#app");
